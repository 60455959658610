/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap"); */

* {
  box-sizing: border-box;
}

html {
  line-height: 1.35;
  font-family: NewFrank_Regular, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 200;
}

body {
  color: #092a49;
  font-family: "Avenir_Regular";
  scroll-behavior: smooth;
}

a {
  color: #092a49;
  font-family: "Avenir_Regular";
}
button {
  font-family: "Avenir_Bold";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  font-family: "Avenir_Bold";
  font-weight: 700;
  color: #092a49;
  font-weight: 800;
}

@font-face {
  font-family: "NewFrank_Regular";
  src: local("NewFrankReg"),
    url("Fonts/NewFrank/New_Frank_Regular.eot") format("eot"),
    url("Fonts/NewFrank/New_Frank_Regular.ttf") format("ttf"),
    url("Fonts/NewFrank/New_Frank_Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "NewFrank_Medium";
  src: local("NewFrankMed"),
    url("Fonts/NewFrank/New_Frank_Medium.eot") format("eot"),
    url("Fonts/NewFrank/New_Frank_Medium.ttf") format("ttf"),
    url("Fonts/NewFrank/New_Frank_Medium.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "NewFrank_Bold";
  src: local("NewFrankBld"),
    url("Fonts/NewFrank/New_Frank_Bold.eot") format("eot"),
    url("Fonts/NewFrank/New_Frank_Bold.ttf") format("ttf"),
    url("Fonts/NewFrank/New_Frank_Bold.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "TradeGothicLT";
  src: local("TradeGothicLT"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.eot") format("eot"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.ttf") format("ttf"),
    url("Fonts/TradeGothic/Trade_Gothic_LT.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "TradeGothicBd";
  src: local("TradeGothicBd"),
    url("Fonts/TradeGothic/TradeGothic_Bold.eot") format("eot"),
    url("Fonts/TradeGothic/TradeGothic_Bold.ttf") format("ttf"),
    url("Fonts/TradeGothic/TradeGothic_Bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir_Regular";
  src: local("Avenir-Reg"), url("Fonts/Avenir/Avenir-Book.eot") format("eot"),
    url("Fonts/Avenir/Avenir-Book.ttf") format("ttf"),
    url("Fonts/Avenir/Avenir-Book.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "Avenir_Medium";
  src: local("Avenir-Med"), url("Fonts/Avenir/Avenir-Medium.eot") format("eot"),
    url("Fonts/Avenir/Avenir-Medium.ttf") format("ttf"),
    url("Fonts/Avenir/Avenir-Medium.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Avenir_Black";
  src: local("Avenir-Blk"), url("Fonts/Avenir/Avenir-Black.eot") format("eot"),
    url("Fonts/Avenir/Avenir-Black.ttf") format("ttf"),
    url("Fonts/Avenir/Avenir-Black.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "Avenir_Bold";
  src: local("Avenir-Bld"), url("Fonts/Avenir/Avenir-Heavy.eot") format("eot"),
    url("Fonts/Avenir/Avenir-Heavy.ttf") format("ttf"),
    url("Fonts/Avenir/Avenir-Heavy.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}

.uppercase {
  text-transform: uppercase;
}
